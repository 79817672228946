/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';

import {
    IoClose, IoCaretUpOutline, IoCaretDownOutline,
} from 'react-icons/io5';
import SlideDown from 'react-slidedown';
import { modalCustomStyles } from '../Utils';
import ButtonGray from './elements/ButtonGray';
import Button from './elements/Button';
import ListItem from './elements/ListItem';
import useAppStore from '../contexts/useAppStore';

const ModalTypes = ['destinations', 'interests', 'suppliers'];

function AgentAreaOfExpertis({ agent, type = 'default' }) {
    const history = useHistory();
    const {

        getDestinations,

    } = useAppStore();
    const [modalType, setModalType] = useState('');
    const [showSupplier, setShowSupplier] = useState(true);
    const [showPopularDestination, setShowPopularDestination] = useState(true);
    const [showPopularInterest, setShowPopularInterest] = useState(true);

    const defaultView = () => (
        <div className=" py-10 md:px-20 px-4 bg-gray-light md:bg-white">
            <div className="flex flex-col gap-6 text-3xl">

                <div className="flex flex-col text-left
     gap-10  w-full md:px-4"
                >
                    {agent.agentDestinations.length > 0 && (
                        <div className="text-gray-800">

                            <div className=" font-bold text-sm md:text-2xl  mb-8 text-left flex flex-row items-center text-blue-dark">
                                <div onClick={() => setShowPopularDestination(!showPopularDestination)} className="block md:hidden">
                                    {showPopularDestination ? <IoCaretUpOutline className="text-lg text-blue-dark" /> : <IoCaretDownOutline className="text-lg text-blue-dark" />}
                                </div>
                                <div> Destinations</div>
                            </div>
                            <SlideDown className="my-dropdown-slidedown">
                                {showPopularDestination
                                    && (
                                        <>
                                            <div className="flex flex-col md:flex-row gap-10 my-4">
                                                <div className="grid grid-cols-2 md:grid-cols-7  gap-6 w-full">
                                                    {agent.agentDestinations.slice(0, 5).map((x) => (
                                                        <ButtonGray
                                                            key={x.locationId}
                                                            onClick={() => history.push(`/agents?destination=${x.locationId}&destinationLabel=${getDestinations.find((y) => y.destinationId === parseInt(x.locationId)).destination}&agentId=${agent.agentId}`)}
                                                            title={x.locationType === 'Cit' ? x.locale : (x.locationType === 'Reg' ? x.region : x.area)}
                                                        />

                                                    ))}
                                                    {agent.agentDestinations.length > 5
                                                        && (
                                                            <Button onClick={() => setModalType('destinations')} title=" See All" />

                                                        )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                            </SlideDown>
                        </div>
                    )}
                    {agent.agentInterests.length > 0 && (
                        <div className="">

                            <div className=" font-bold text-sm md:text-2xl  mb-8 text-left flex flex-row items-center text-blue-dark">
                                <div onClick={() => setShowPopularInterest(!showPopularInterest)} className="block md:hidden">
                                    {showPopularInterest ? <IoCaretUpOutline className="text-lg text-blue-dark" /> : <IoCaretDownOutline className="text-lg text-blue-dark" />}
                                </div>
                                <div> Interests</div>
                            </div>
                            <SlideDown className="my-dropdown-slidedown">
                                {showPopularInterest
                                    && (
                                        <>
                                            <div className="flex flex-col md:flex-row gap-10 my-4">
                                                <div className="grid grid-cols-2 md:grid-cols-7  gap-6 w-full">
                                                    {agent.agentInterests.slice(0, 5).map((x) => (
                                                        <ButtonGray
                                                            key={x.interestName}
                                                            onClick={() => history.push(`/agents?interest=${encodeURIComponent(x.interestName)}&agentId=${agent.agentId}`)}
                                                            title={x.interestName}
                                                        />

                                                    ))}
                                                    {agent.agentInterests.length > 5
                                                        && (
                                                            <Button onClick={() => setModalType('interests')} title=" See All" />

                                                        )}

                                                </div>

                                            </div>
                                        </>
                                    )}
                            </SlideDown>

                        </div>
                    )}

                    {agent.agentSuppliers.length > 0 && (
                        <div className="">

                            <div className=" font-bold text-sm md:text-2xl  mb-8 text-left flex flex-row items-center text-blue-dark">
                                <div onClick={() => setShowSupplier(!showSupplier)} className="block md:hidden">
                                    {showSupplier ? <IoCaretUpOutline className="text-lg text-blue-dark" /> : <IoCaretDownOutline className="text-lg text-blue-dark" />}
                                </div>
                                <div> Supplier Partner</div>
                            </div>
                            <SlideDown className="my-dropdown-slidedown">
                                {showSupplier
                                    && (
                                        <>
                                            <div className="flex flex-col items-center md:items-start md:flex-row gap-10 my-4">
                                                <div className="grid grid-cols-2 md:grid-cols-7  gap-6 w-full">
                                                    {agent.agentSuppliers.slice(0, 5).map((x) => (
                                                        <ButtonGray
                                                            key={x.supplierName}
                                                            onClick={() => history.push(`/agents?supplier=${encodeURIComponent(x.supplierName)}&agentId=${agent.agentId}`)}
                                                            title={x.supplierName}
                                                        />

                                                    ))}
                                                    {agent.agentSuppliers.length > 5
                                                        && (
                                                            <Button onClick={() => setModalType('suppliers')} title=" See All" />

                                                        )}

                                                </div>

                                            </div>
                                        </>
                                    )}
                            </SlideDown>
                        </div>
                    )}
                </div>

            </div>

            <Modal
                isOpen={ModalTypes.some((x) => x === modalType)}
                onRequestClose={() => setModalType('')}
                style={modalCustomStyles}
                contentLabel="Modal"
                ariaHideApp={false}
            >
                <div className="max-w-6xl">
                    <div className="flex justify-end cursor-pointer" onClick={() => setModalType('')}>
                        <IoClose className="text-red-500 text-right" />
                    </div>
                    <div className=" px-4">
                        {modalType === 'interests'
                            && (
                                <div className="grid grid-cols-2 gap-1 text-xs gap-x-10">

                                    {agent.agentInterests.map((x) => (
                                        <div
                                            key={x.interestName}
                                            onClick={() => history.push(`/agents?interest=${x.interestName}`)}
                                        >

                                            <ListItem title={x.interestName} isClickable />
                                        </div>
                                    ))}
                                </div>
                            )}

                        {modalType === 'destinations' && (
                            <div className="grid grid-cols-2 gap-1 text-xs gap-x-10">
                                {agent.agentDestinations.map((x) => {
                                    const destinationLabel = getDestinations.find(
                                        (y) => y.destinationId === parseInt(x.locationId),
                                    )?.destination;

                                    return (
                                        <div
                                            key={x.locationId}
                                            onClick={() => {
                                                if (destinationLabel) {
                                                    history.push(`/agents?destination=${x.locationId}&destinationLabel=${encodeURIComponent(destinationLabel)}&agentId=${agent.agentId}`);
                                                }
                                            }}
                                        >
                                            <ListItem
                                                title={x.locationType === 'Cit' ? x.locale : (x.locationType === 'Reg' ? x.region : x.area)}
                                                isClickable
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}

                        {modalType === 'suppliers'
                            && (
                                <div className="grid grid-cols-2 gap-1 text-xs gap-x-10">

                                    {agent.agentSuppliers.map((x) => (
                                        <div
                                            key={x.supplierName}
                                            onClick={() => history.push(`/agents?supplier=${x.supplierName}`)}
                                        >

                                            <ListItem title={x.supplierName} isClickable />
                                        </div>
                                    ))}
                                </div>
                            )}

                        {modalType === 'travel' && (
                            <div className="h-56 flex justify-center items-center">
                                REDIRECT TO  ADDITIONAL BIO
                            </div>
                        )}

                    </div>
                </div>
            </Modal>

        </div>
    );

    const bioView = () => (
        <div className=" py-10 md:px-20 px-4 bg-gray-light md:bg-white">
            <div className="flex flex-col gap-6 text-3xl">

                <div className="flex flex-col text-left
   gap-10  w-full md:px-4"
                >
                    {agent.agentSpecialtyTags.filter((x) => x.tagTypeName === 'Destination').length > 0 && (
                        <div className="text-gray-800">

                            <div className=" font-bold text-sm md:text-2xl  mb-8 text-left flex flex-row items-center text-blue-dark">
                                <div onClick={() => setShowPopularDestination(!showPopularDestination)} className="block md:hidden">
                                    {showPopularDestination ? <IoCaretUpOutline className="text-lg text-blue-dark" /> : <IoCaretDownOutline className="text-lg text-blue-dark" />}
                                </div>
                                <div> Destinations</div>
                            </div>
                            <SlideDown className="my-dropdown-slidedown">
                                {showPopularDestination
                                    && (
                                        <>
                                            <div className="flex flex-col md:flex-row gap-10 my-4">
                                                <div className="grid grid-cols-2 md:grid-cols-7  gap-6 w-full">
                                                    {agent.agentSpecialtyTags.filter((x) => x.tagTypeName === 'Destination').slice(0, 5).map((x) => (
                                                        <ButtonGray
                                                            key={x.specialtyId}
                                                            onClick={() => history.push(`/agents?destination=${x.specialtyId}`)}
                                                            title={x.specialtyName}
                                                        />

                                                    ))}
                                                    {agent.agentSpecialtyTags.filter((x) => x.tagTypeName === 'Destination').length > 5
                                                        && (
                                                            <Button onClick={() => setModalType('destinations')} title=" See All" />

                                                        )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                            </SlideDown>
                        </div>
                    )}
                    {agent.agentSpecialtyTags.filter((x) => x.tagTypeName === 'Interest').length > 0 && (
                        <div className="">

                            <div className=" font-bold text-sm md:text-2xl  mb-8 text-left flex flex-row items-center text-blue-dark">
                                <div onClick={() => setShowPopularInterest(!showPopularInterest)} className="block md:hidden">
                                    {showPopularInterest ? <IoCaretUpOutline className="text-lg text-blue-dark" /> : <IoCaretDownOutline className="text-lg text-blue-dark" />}
                                </div>
                                <div> Interests</div>
                            </div>
                            <SlideDown className="my-dropdown-slidedown">
                                {showPopularInterest
                                    && (
                                        <>
                                            <div className="flex flex-col md:flex-row gap-10 my-4">
                                                <div className="grid grid-cols-2 md:grid-cols-7  gap-6 w-full">
                                                    {agent.agentSpecialtyTags.filter((x) => x.tagTypeName === 'Interest').slice(0, 5).map((x) => (
                                                        <ButtonGray
                                                            key={x.specialtyId}
                                                            onClick={() => history.push(`/agents?interest=${encodeURIComponent(x.specialtyName)}`)}
                                                            title={x.specialtyName}
                                                        />

                                                    ))}
                                                    {agent.agentSpecialtyTags.filter((x) => x.tagTypeName === 'Interest').length > 5
                                                        && (
                                                            <Button onClick={() => setModalType('interests')} title=" See All" />

                                                        )}

                                                </div>

                                            </div>
                                        </>
                                    )}
                            </SlideDown>

                        </div>
                    )}

                    {agent.agentSpecialtyTags.filter((x) => x.tagTypeName === 'Supplier').length > 0 && (
                        <div className="">

                            <div className=" font-bold text-sm md:text-2xl  mb-8 text-left flex flex-row items-center text-blue-dark">
                                <div onClick={() => setShowSupplier(!showSupplier)} className="block md:hidden">
                                    {showSupplier ? <IoCaretUpOutline className="text-lg text-blue-dark" /> : <IoCaretDownOutline className="text-lg text-blue-dark" />}
                                </div>
                                <div> Supplier Partner</div>
                            </div>
                            <SlideDown className="my-dropdown-slidedown">
                                {showSupplier
                                    && (
                                        <>
                                            <div className="flex flex-col items-center md:items-start md:flex-row gap-10 my-4">
                                                <div className="grid grid-cols-2 md:grid-cols-7  gap-6 w-full">
                                                    {agent.agentSpecialtyTags.filter((x) => x.tagTypeName === 'Supplier').slice(0, 5).map((x) => (
                                                        <ButtonGray
                                                            key={x.specialtyId}
                                                            onClick={() => history.push(`/agents?supplier=${encodeURIComponent(x.specialtyName)}`)}
                                                            title={x.specialtyName}
                                                        />

                                                    ))}
                                                    {agent.agentSpecialtyTags.filter((x) => x.tagTypeName === 'Supplier').length > 5
                                                        && (
                                                            <Button onClick={() => setModalType('suppliers')} title=" See All" />

                                                        )}

                                                </div>

                                            </div>
                                        </>
                                    )}
                            </SlideDown>
                        </div>
                    )}
                </div>

            </div>

            <Modal
                isOpen={ModalTypes.some((x) => x === modalType)}
                onRequestClose={() => setModalType('')}
                style={modalCustomStyles}
                contentLabel="Modal"
                ariaHideApp={false}
            >
                <div className="max-w-6xl">
                    <div className="flex justify-end cursor-pointer" onClick={() => setModalType('')}>
                        <IoClose className="text-red-500 text-right" />
                    </div>
                    <div className=" px-4">
                        {modalType === 'interests'
                            && (
                                <div className="grid grid-cols-2 gap-1 text-xs gap-x-10">

                                    {agent.agentInterests.map((x) => (
                                        <div
                                            key={x.interestName}
                                            onClick={() => history.push(`/agents?interest=${x.interestName}`)}
                                        >

                                            <ListItem title={x.interestName} isClickable />
                                        </div>
                                    ))}
                                </div>
                            )}

                        {modalType === 'destinations'
                            && (
                                <div className="grid grid-cols-2 gap-1 text-xs gap-x-10">

                                    {agent.agentDestinations.map((x) => (
                                        <div
                                            key={x.locationId}
                                            onClick={() => history.push(`/agents?destination=${x.locationId}&destinationLabel=${x.locationName}`)}
                                        >

                                            <ListItem
                                                title={x.locationType === 'Cit' ? x.locale : (x.locationType === 'Reg' ? x.region : x.area)}
                                                isClickable
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}

                        {modalType === 'suppliers'
                            && (
                                <div className="grid grid-cols-2 gap-1 text-xs gap-x-10">

                                    {agent.agentSuppliers.map((x) => (
                                        <div
                                            key={x.supplierName}
                                            onClick={() => history.push(`/agents?supplier=${x.supplierName}`)}
                                        >

                                            <ListItem title={x.supplierName} isClickable />
                                        </div>
                                    ))}
                                </div>
                            )}

                        {modalType === 'travel' && (
                            <div className="h-56 flex justify-center items-center">
                                REDIRECT TO  ADDITIONAL BIO
                            </div>
                        )}

                    </div>
                </div>
            </Modal>

        </div>
    );
    return (
        <>
            {type === 'default' ? defaultView() : bioView()}
        </>
    );
}

export default AgentAreaOfExpertis;
