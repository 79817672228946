/* eslint-disable prefer-spread */
/* eslint-disable-next-line react/jsx-one-expression-per-line */

import React, { useEffect, useState } from 'react';
import {
    IoClose, IoCall, IoMail, IoCalendar, IoLocationSharp, IoCheckmarkCircleSharp,
} from 'react-icons/io5';
import PropTypes from 'prop-types';
import parser from 'html-react-parser';
import { useLocation, useHistory } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import Modal from 'react-modal';
import Select, { createFilter } from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import useMobileDetect from 'use-mobile-detect-hook';
import Button from '../components/elements/Button';
import 'react-loading-skeleton/dist/skeleton.css';
import Loading from '../components/elements/Loading';
import LoadingInline from '../components/elements/LoadingInline';
import SelectInput from '../components/elements/SelectInput';
import useAppStore from '../contexts/useAppStore';
import API from '../services/API';
import {
    AD_URL,
    callMe,
    CONTACT_METHOD_EMAIL,
    CONTACT_METHOD_PHONE,
    CONTACT_METHOD_VIDEO,
    customSelectStyles, filterConfig, getStateName, modalCustomStyles,
} from '../Utils';
import EmailForm from '../components/EmailForm';
import PhoneForm from '../components/PhoneForm';
import 'react-toastify/dist/ReactToastify.css';
import Destination from '../components/search/Destination';
import Interest from '../components/search/Interest';
import Supplier from '../components/search/Supplier';
import Language from '../components/search/Language';
import State from '../components/search/State';
import Input from '../components/elements/Input';
import BreadCrumb from '../components/BreadCrumb';
import AppointmentForm from '../components/AppointmentForm';
import EmergencyEmailForm from '../components/EmergencyEmailForm';
import ButtonBlue from '../components/elements/ButtonBlue';

function Agents() {
    const history = useHistory();
    const detectMobile = useMobileDetect();
    const location = useLocation();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const [visiibleFilter, setVisibleFilter] = useState(false);

    const query = useQuery();

    const {
        getStates,
        getDestinations,
        getSpecialities,
        setFetchingAgents,
        isFetchingAgent,
    } = useAppStore();

    const [currentPage, setCurrentPage] = useState(0);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeFilters, setActiveFilters] = useState({
        destination: query.get('destination') || '',
        state: query.get('state') || '',
        interest: query.get('interest') || '',
        metro: query.get('metro') || '',
        agentName: query.get('agentName') || '',
        zipCode: query.get('zipCode') || '',
        language: query.get('language') || '',
        supplier: query.get('supplier') || '',
        city: query.get('city') || '',
    });

    const [links, setLinks] = useState([{
        label: 'Home',
        url: '/',
    },
    {
        label: 'Find a travel Agent',
        url: '/find_travel_agent',
    },
    {
        label: 'All Agents',

    },
    ]);

    // Search Filters
    const [selectedDestination, setSelectedDestination] = useState(query.get('destination') || '');

    const [selectedDestinationObject, setSelectedDestinationObject] = useState(query.get('destination') ? {
        value: query.get('destination'),
        label: query.get('destinationLabel'),
    } : null);
    const [selectedState, setSelectedState] = useState(query.get('state') || '');
    const [selectedInterest, setSelectedInterest] = useState(query.get('interest') || '');

    /**
   * Temp
   */
    const [selectedDestinationObjectTemp, setSelectedDestinationObjectTemp] = useState(query.get('destination') ? {
        value: query.get('destination') ? parseInt(query.get('destination')) : 0,
        label: query.get('destinationLabel') || '',
    } : null);
    const [selectedZipCodeTemp, setSelectedZipCodeTemp] = useState(query.get('zipCode') || '');
    const [selectedInterestTemp, setSelectedInterestTemp] = useState(query.get('interest') || '');

    const [selectedMetro, setSelectedMetro] = useState(query.get('metro') || '');
    const [selectedCity, setSelectedCity] = useState(query.get('city') || '');
    const [agentName, setAgentName] = useState(query.get('agentName') || '');
    const [zipCode, setZipCode] = useState(query.get('zipCode') || '');
    const [agentId, setAgentId] = useState(query.get('agentId') || 0);

    const [selectedLanguage, setSelectedLanguage] = useState(query.get('language') || '');
    const [selectedSupplier, setSelectedSupplier] = useState(query.get('supplier') || '');

    // Agents
    const [agents, setAgents] = useState();
    const [isFetchingAgentMore, setFetchingAgentsMore] = useState();
    const {
        setIsEmailFormModalOpen, setIsPhoneFormModalOpen, setIsAppointmentFormModalOpen,
        isEmailFormModalOpen, isPhoneFormModalOpen, isAppointmentFormModalOpen,
        isEmergencyFromModalOpen, closeFormModal,
    } = useAppStore();
    const [isReady, setIsReady] = useState();
    const [selectedAgent, setSelectedAgent] = useState();
    const [dynamicTitle, setDynamicTitle] = useState();
    const [dynamicMetaTitle, setDynamicMetaTitle] = useState();
    const [dynamicDescription, setDynamicDescription] = useState();

    const [filter, setFilter] = useState(false);

    const [ads, setAds] = useState([]);

    const amendDestination = (data, dest) => {
        if (dest === '') {
            return data;
        }
        let localeName = null;
        let areaName = null;
        let regionName = null;
        let destinationArr = [];
        const amendedDestinations = data || [];

        if (getDestinations.some((x) => x.destinationId === parseInt(dest))) {
            const d = getDestinations.find((z) => z.destinationId === parseInt(dest));
            destinationArr = d.destination?.split('|');

            if (destinationArr.length === 3) {
                localeName = destinationArr[0].trim();
                areaName = destinationArr[1].trim();
                regionName = destinationArr[2].trim();
            } else if (destinationArr.length === 2) {
                areaName = destinationArr[0].trim();
                regionName = destinationArr[1].trim();
            } else if (destinationArr.length === 1) {
                regionName = destinationArr[0].trim();
            }

            if (data && !data.some((x) => x.localeName === localeName
                && x.regionName === regionName
                && x.areaName === areaName)) {
                amendedDestinations.push({
                    locationType: destinationArr.length === 3 ? 'Cit' : (destinationArr.length === 2 ? 'Are' : 'Reg'),
                    regionName,
                    areaName,
                    localeName,
                });
            }
        } else {
            amendedDestinations.push({
                locationType: destinationArr.length === 3 ? 'Cit' : (destinationArr.length === 2 ? 'Are' : 'Reg'),
                regionName,
                areaName,
                localeName,
            });
        }
        return amendedDestinations;
    };
    const amendInterest = (data, inrst) => {
        if (inrst === '') {
            return data;
        }
        const amendedInterest = data || [];
        if (data.length > 0) {
            if (!data.some((x) => x.interestName === inrst)) {
                amendedInterest.push({
                    interestName: inrst,
                });
            }
        } else {
            amendedInterest.push({
                interestName: inrst,
            });
        }
        return amendedInterest;
    };

    const onSearch = (clearPrevious = true) => {
        if (!clearPrevious) { setFetchingAgentsMore(true); } else { setFetchingAgents(true); }
        if (clearPrevious) {
            setCurrentPage(0);
        }

        API.fetchAgents({
            zip: zipCode || activeFilters.zipCode,
            name: agentName || activeFilters.agentName,
            state: selectedState || activeFilters.state,
            destination: selectedDestinationObject ? selectedDestinationObject?.label?.split('|')[0].trim() : getDestinationName(),
            interest: selectedInterest || activeFilters.interest,
            metroRegion: selectedMetro || activeFilters.metro,
            language: selectedLanguage || activeFilters.language,
            supplier: selectedSupplier || activeFilters.supplier,
            agentId,
            city: selectedCity || activeFilters.city,
            currentPage: clearPrevious ? 0 : currentPage,
        }).then((result) => {
            if (result.responseStatus === 1) {
                if (!agents || clearPrevious) {
                    result.data.agent.map((y) => {
                        y.destination = amendDestination(y.destination ? y.destination.splice(0, 5) : [],
                            selectedDestinationObject ? selectedDestinationObject.value
                                : activeFilters.destination);

                        y.interest = amendInterest(y.interest ? y.interest.splice(0, 5) : [],
                            selectedInterest || activeFilters.interest);
                    });
                    setAgents(result.data);
                } else {
                    result.data.agent.map((y) => {
                        y.destination = amendDestination(y.destination ? y.destination.splice(0, 5) : [],
                            selectedDestinationObject ? selectedDestinationObject.value
                                : activeFilters.destination);
                        y.interest = amendInterest(y.interest ? y.interest.splice(0, 5) : [],
                            selectedInterest || activeFilters.interest);
                    });

                    agents.agent = agents.agent.concat(result.data.agent);

                    agents.totalAgents = result.data.totalAgents;
                    setAgents({ ...agents });
                }

                setActiveFilters(

                    {
                        destination: selectedDestinationObject ? selectedDestinationObject.value
                            : activeFilters.destination,
                        state: selectedState || activeFilters.state,
                        interest: selectedInterest || activeFilters.interest,
                        metro: selectedMetro || activeFilters.metro,
                        agentName: agentName || activeFilters.agentName,
                        zipCode: zipCode || activeFilters.zipCode,
                        language: selectedLanguage || activeFilters.language,
                        supplier: selectedSupplier || activeFilters.supplier,
                        city: selectedCity || activeFilters.city,
                    },

                );
            } else {
                toast.error(result.error.message);
            }

            setFetchingAgents(false);
            setFetchingAgentsMore(false);
            setIsReady(false);
        });
    };

    const onRefineSearch = () => {
        setSelectedInterest(selectedInterestTemp);
        setSelectedDestinationObject(selectedDestinationObjectTemp);
        setSelectedState();
        setActiveFilters(
            {
                ...activeFilters, state: '',
            },
        );
        setZipCode(selectedZipCodeTemp);
        // onSearch();
    };

    const emailMe = (agent) => {
        // connect.find((x) => x.contactMethod === 'by Email')?.contactValue
        setSelectedAgent(agent);
        setIsEmailFormModalOpen(true);
    };

    const constructTitle = () => {
        let title = '';

        if (activeFilters.destination || activeFilters.interest) {
            if (activeFilters.destination) { title += `${getDestinationName()} `; }
            if (activeFilters.interest) { title += `${activeFilters.interest} `; }
        }

        if (activeFilters.state) {
            title += `Travel Agents in ${activeFilters.metro && !activeFilters.city ? `${activeFilters.metro},` : ''} ${activeFilters.city ? `${activeFilters.city}` : ''} 
    ${getStateName(activeFilters.state)}`;
        } else if (activeFilters.zipCode) { title += `Travel Agents near ${activeFilters.zipCode}`; } else { title += 'Travel Agents'; }

        setDynamicTitle(title);
    };

    const constructMetaTitle = () => {
        let metaTitle = '';

        if (activeFilters.destination || activeFilters.interest) {
            if (activeFilters.destination) { metaTitle += `${getDestinationName()} `; }
            if (activeFilters.interest) { metaTitle += `${activeFilters.interest} `; }
        }

        if (activeFilters.state) {
            metaTitle += `Travel Agents in ${activeFilters.metro && !activeFilters.city ? `${activeFilters.metro},` : ''} ${activeFilters.city ? `${activeFilters.city}` : ''} 
    ${getStateName(activeFilters.state)} | Vacation.com`;
        } else if (activeFilters.zipCode) { metaTitle += `Travel Agents near ${activeFilters.zipCode}`; } else { metaTitle += 'Travel Agents | Vacation'; }

        setDynamicMetaTitle(metaTitle);
    };

    const constructDescription = () => {
        let description = '';

        description += `See ${agents?.totalAgents} Travel Agents${getStateName(activeFilters.state) ? ' in ' + getStateName(activeFilters.state) : ''}${activeFilters?.interest || activeFilters?.destination ? ' who specialize in ' + getDestinationName(activeFilters.destination) : ''}${activeFilters.interest ? ' ' + activeFilters.interest : ''} who can provide recommendations, knowledge and exclusive vacations.`;

        setDynamicDescription(description);
    };

    const goToAgentBio = (agent, section = '') => {
        const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));

        localStorage.setItem('previousDestination', JSON.stringify(queryParams));

        const newUrl = section !== '' ? `/agent/${agent.agentId}?s=${section}` : `/agent/${agent.agentId}`;
        history.push(newUrl);
    };

    const closeModal = () => {
        closeFormModal();
    };

    const getDestinationName = () => {
        if (getDestinations
            .some((x) => x.destinationId === parseInt(activeFilters.destination))) {
            return getDestinations
                .find((x) => x.destinationId === parseInt(activeFilters.destination)).destination?.split('|')[0].trim();
        }

        return activeFilters.destination ? '-' : '';
    };

    const getAds = () => {
        API.getAdverts({
            destination: getDestinationName(),
            interest: activeFilters.interest,
            supplier: activeFilters.supplier,

        }).then((result) => {
            if (result.responseStatus === 1) {
                setAds(result.data.sort((a, b) => b.sortOrder - a.sortOrder).map((x) => x));
            }
        });
    };

    useEffect(() => {
        if (isReady) {
            onSearch();
            window.scrollTo(0, 0);
        }
    }, [isReady]);

    useEffect(() => {
        constructTitle();
        if ((activeFilters.destination !== '' || activeFilters.interest !== '' || activeFilters.supplier !== '') && isReady) {
            getAds();
        } else {
            setAds([]);
        }
    }, [activeFilters, isReady]);

    useEffect(() => {
        constructMetaTitle();
        if ((activeFilters.destination !== '' || activeFilters.interest !== '' || activeFilters.supplier !== '') && isReady) {
            getAds();
        } else {
            setAds([]);
        }
    }, [activeFilters, isReady]);

    useEffect(() => {
        constructDescription();
        if ((activeFilters.destination !== '' || activeFilters.interest !== '' || activeFilters.supplier !== '') && isReady) {
            getAds();
        } else {
            setAds([]);
        }
    }, [activeFilters, isReady]);

    useEffect(() => {
        if (getDestinations.length > 0) {
            setIsReady(true);
        }
    }, [getDestinations]);

    useEffect(() => {
        if (currentPage > 0) { onSearch(false); }
    }, [currentPage]);

    // useEffect(() => {
    //   window.location.reload();
    // }, [location.search]);

    useEffect(() => history.listen((a) => {
        if (history.action === 'PUSH') {
            window.location.reload();
        }

        if (history.action === 'POP') {
            window.location.reload();
        }
    }), []);

    const handleWindowSizeChange = () => {
        if (window.innerWidth < 768) { setFilter(false); } else { setFilter(true); }
    };
    const listenToScroll = () => {
        const heightToHideFrom = 225;

        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll > heightToHideFrom) { setVisibleFilter(true); } else { setVisibleFilter(false); }
    };
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        window.addEventListener('scroll', listenToScroll);
        return () => {
            window.removeEventListener('resize', listenToScroll);
            window.removeEventListener('scroll', handleWindowSizeChange);
        };
    }, []);

    const searchViaRoute = () => {
        const filters = [];
        if (zipCode) {
            filters.push(`zipCode=${encodeURIComponent(zipCode)}`);
        }
        if (selectedInterest) {
            filters.push(`interest=${encodeURIComponent(selectedInterest)}`);
        }
        if (selectedDestinationObject) {
            filters.push(`destinationLabel=${encodeURIComponent(selectedDestinationObject?.label?.split('|')[0].trim())}&destination=${selectedDestinationObject.value}`);
        }
        if (selectedState) {
            filters.push(`state=${encodeURIComponent(selectedState)}`);
            if (selectedMetro) {
                filters.push(`metro=${encodeURIComponent(selectedMetro)}`);
            }
        }
        if (selectedCity) {
            filters.push(`city=${encodeURIComponent(selectedCity)}`);
        }
        if (selectedLanguage) {
            filters.push(`language=${encodeURIComponent(selectedLanguage)}`);
        }

        if (selectedSupplier) {
            filters.push(`supplier=${encodeURIComponent(selectedSupplier)}`);
        }

        history.push(`/agents?${filters.join('&')}`);

        // history.go();
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [filter]);

    const constructLinks = () => {
        const base = '/agents';
        const baseLinks = [{
            label: 'Home',
            url: '/',
        },
        {
            label: 'Find a Travel Agent',
            url: '/find_travel_agent',
        },

        ];

        if (query.get('state')) {
            baseLinks.push({
                label: getStateName(query.get('state')),
                url: `${base}?state=${query.get('state')}`,

            });
            if (query.get('metro')) {
                if (query.get('city')) {
                    baseLinks.push({
                        label: query.get('city'),
                        url: `${base}?state=${query.get('state')}&metro=${query.get('metro')}&&city=${query.get('city')}`,

                    });
                } else {
                    baseLinks.push({
                        label: query.get('metro'),
                        url: `${base}?state=${query.get('state')}&metro=${query.get('metro')}`,

                    });
                }
            }
        }
        if (query.get('destination')) {
            let url = `${base}?destinationLabel=${query.get('destinationLabel')}&destination=${query.get('destination')}`;

            if (query.get('state')) {
                url += `&state=${query.get('state')}`;
            }

            if (query.get('metro')) {
                url += `&metro=${query.get('metro')}`;
            }

            baseLinks.push({
                label: getStateName(query.get('destinationLabel').split('|')[0].trim()),
                url,
            });
        }
        if (query.get('interest')) {
            // interest destination state/metro

            let url = `${base}?interest=${query.get('interest')}`;

            if (query.get('state')) {
                url += `&state=${query.get('state')}`;
            }

            if (query.get('metro')) {
                url += `&metro=${query.get('metro')}`;
            }

            if (query.get('destination')) {
                url += `&destinationLabel=${query.get('destinationLabel')}&destination=${query.get('destination')}`;
            }

            baseLinks.push({
                label: getStateName(query.get('interest')),
                url,
            });
        }

        if (query.get('supplier')) {
            // interest destination state/metro interest

            let url = `${base}?supplier=${query.get('supplier')}`;

            if (query.get('state')) {
                url += `&state=${query.get('state')}`;
            }

            if (query.get('metro')) {
                url += `&metro=${query.get('metro')}`;
            }

            if (query.get('destination')) {
                url += `&destinationLabel=${query.get('destinationLabel')}&destination=${query.get('destination')}`;
            }
            if (query.get('interest')) {
                url += `&interest=${query.get('interest')}`;
            }

            baseLinks.push({
                label: getStateName(query.get('supplier')),
                url,
            });
        }
        if (query.get('language')) {
            // interest destination state/metro supplier

            let url = `${base}?language=${query.get('language')}`;

            if (query.get('state')) {
                url += `&state=${query.get('state')}`;
            }

            if (query.get('metro')) {
                url += `&metro=${query.get('metro')}`;
            }

            if (query.get('destination')) {
                url += `&destinationLabel=${query.get('destinationLabel')}&destination=${query.get('destination')}`;
            }
            if (query.get('supplier')) {
                url += `&supplier=${query.get('supplier')}`;
            }

            baseLinks.push({
                label: getStateName(query.get('language')),
                url,
            });
        }

        if (query.get('agentName') || query.get('zipCode')) {
            baseLinks.push({
                label: 'All Agents',

            });
        }

        if (location.search.indexOf('=') === -1) {
            baseLinks.push({
                label: 'All Agents',

            });
        }
        setLinks(baseLinks);
    };

    useEffect(() => {
        constructLinks();
    }, []);

    const getAgentBio = (agent, callback) => {
        isFetchingAgent(true);
        API.agentBio({
            agentId: agent.agentId,

        }).then((result) => {
            if (result.responseStatus === 1) {
                setSelectedAgent(result.data);
                if (callback) { callback(); }
            } else { toast(result.error.message); }
            isFetchingAgent(false);
        });
    };

    const onVideoAppointment = (agent) => {
        getAgentBio(agent, () => setIsAppointmentFormModalOpen(true));
    };

    const label = (val) => <div className="my-2 font-semibold text-sm uppercase text-black">{val}</div>;

    const profileButton = (agent) => {
        const handleClick = () => {
            const destination = activeFilters?.destination || '';
            const destinationLabel = getDestinationName(activeFilters?.destination) || '';

            const searchParams = new URLSearchParams({
                destination,
                destinationLabel,
            }).toString();

            history.push(`/agent/${agent.agentId}?${searchParams}`, {
                state: { bookedVisitCnt: agent.bookedVisitCnt },
            });
        };

        return (
            <div
                className={[
                    'emailme flex items-center justify-center gap-2 rounded-full w-full max-w-[180px] h-10 px-6 mt-1 cursor-pointer text-white text-xs font-semibold whitespace-nowrap',
                    agent.isOffline === '1' ? 'bg-gray-300' : 'bg-blue-dark hover:bg-green-light',
                ].join(' ')}
                onClick={handleClick}
            >
                <span className="flex-shrink-0">PROFILE</span>
            </div>
        );
    };

    const actionButtonsDesk = (agent) => (
        <div className="flex flex-col gap-2 items-center p-1">
            {agent.connect.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL) && (
                <div
                    className={['emailme flex items-center justify-center gap-2 rounded-full w-full max-w-[180px] h-10 px-6 cursor-pointer text-white text-xs font-semibold whitespace-nowrap',
                        agent.isOffline === '1' ? 'bg-gray-300' : 'bg-blue-dark hover:bg-green-light'].join(' ')}
                    onClick={() => (agent.isOffline !== '1' ? emailMe(agent) : {})}
                >
                    <IoMail className="w-5 h-5 flex-shrink-0" />
                    <span className="flex-shrink-0">EMAIL ME</span>
                </div>
            )}
            {agent.connect.some((x) => x.contactMethod === CONTACT_METHOD_PHONE) && (
                <div
                    className={['callme flex items-center justify-center gap-2 rounded-full w-full max-w-[180px] h-10 px-6 cursor-pointer text-white text-xs font-semibold whitespace-nowrap',
                        agent.isOffline === '1' ? 'bg-gray-300' : 'bg-blue-dark hover:bg-green-light'].join(' ')}
                    onClick={() => (agent.isOffline !== '1' ? callMe(agent, () => {
                        setSelectedAgent(agent);
                        setIsPhoneFormModalOpen(true);
                    }) : {})}
                >
                    <IoCall className="w-5 h-5 flex-shrink-0" />
                    <span className="flex-shrink-0">CALL ME</span>
                </div>
            )}
            {agent.connect.some((x) => x.contactMethod === CONTACT_METHOD_VIDEO) && (
                <div
                    className={['videome flex items-center justify-center gap-2 rounded-full w-full max-w-[180px] h-10 px-6 cursor-pointer text-white text-xs font-semibold whitespace-nowrap',
                        agent.isOffline === '1' ? 'bg-gray-300' : 'bg-blue-dark hover:bg-green-light'].join(' ')}
                    onClick={() => (agent.isOffline !== '1' ? onVideoAppointment(agent) : {})}
                >
                    <IoCalendar className="w-5 h-5 flex-shrink-0" />
                    <span className="flex-shrink-0">VIDEO APPT</span>
                </div>
            )}
        </div>
    );

    const actionButtonsMob = (agent) => (
        <div className="flex gap-2 items-center p-1 md:hidden w-full justify-evenly">
            {agent.connect.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL) && (
                <div
                    className={['emailme flex items-center justify-center gap-2 rounded-full flex-1 h-10 px-6 cursor-pointer text-white text-xs font-semibold whitespace-nowrap',
                        agent.isOffline === '1' ? 'bg-gray-300' : 'bg-blue-dark hover:bg-green-light'].join(' ')}
                    onClick={() => (agent.isOffline !== '1' ? emailMe(agent) : {})}
                >
                    <IoMail className="w-5 h-5 flex-shrink-0" />
                </div>
            )}
            {agent.connect.some((x) => x.contactMethod === CONTACT_METHOD_PHONE) && (
                <div
                    className={['callme flex items-center justify-center gap-2 rounded-full flex-1 h-10 px-6 cursor-pointer text-white text-xs font-semibold whitespace-nowrap',
                        agent.isOffline === '1' ? 'bg-gray-300' : 'bg-blue-dark hover:bg-green-light'].join(' ')}
                    onClick={() => (agent.isOffline !== '1' ? callMe(agent, () => {
                        setSelectedAgent(agent);
                        setIsPhoneFormModalOpen(true);
                    }) : {})}
                >
                    <IoCall className="w-5 h-5 flex-shrink-0" />
                </div>
            )}
            {agent.connect.some((x) => x.contactMethod === CONTACT_METHOD_VIDEO) && (
                <div
                    className={['videome flex items-center justify-center gap-2 rounded-full flex-1 h-10 px-6 cursor-pointer text-white text-xs font-semibold whitespace-nowrap',
                        agent.isOffline === '1' ? 'bg-gray-300' : 'bg-blue-dark hover:bg-green-light'].join(' ')}
                    onClick={() => (agent.isOffline !== '1' ? onVideoAppointment(agent) : {})}
                >
                    <IoCalendar className="w-5 h-5 flex-shrink-0" />
                </div>
            )}
        </div>
    );

    const agentCard = (agent) => (
        <div className="flex flex-col md:flex-row gap-2 bg-white rounded-tl-3xl shadow-lg justify-between align-top pb-4 md:pb-0 p-6" key={agent.agentId}>

            <div className="flex flex-row items-center md:items-stretch gap-2 md:text-center text-xs w-full md:w-56">

                <div className="w-28 md:w-56 flex flex-col items-center">
                    <img
                        alt={`${agent.firstName} ${agent.lastName}: ${getDestinationName(activeFilters?.destination)} ${activeFilters?.interest} Travel Agent in ${agent.city}, ${agent.state}`}
                        src={agent.agentPhotoFileName}
                        className="w-full md:w-56 md:mx-auto md:rounded-tl-lg rounded-tl-3xl cursor-pointer object-cover"
                        onClick={() => {
                            const searchParams = new URLSearchParams({
                                destination: activeFilters?.destination || '',
                                destinationLabel: getDestinationName(activeFilters?.destination) || '',
                            }).toString();

                            history.push(`/agent/${agent.agentId}?${searchParams}`, {
                                state: { bookedVisitCnt: agent.bookedVisitCnt },
                            });
                        }}
                    />

                    <div className="block md:hidden w-full mt-2">
                        {profileButton(agent)}
                    </div>
                </div>

                <div className="text-black text-xl font-semibold cursor-pointer md:hidden flex flex-col gap-1 flex-1">
                    <div>
                        <span
                            className="cursor-pointer hover:underline"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                const destination = activeFilters?.destination || '';
                                const destinationLabel = getDestinationName(activeFilters?.destination) || '';

                                const searchParams = new URLSearchParams({
                                    destination,
                                    destinationLabel,
                                }).toString();

                                history.push(`/agent/${agent.agentId}?${searchParams}`, {
                                    state: { bookedVisitCnt: agent.bookedVisitCnt },
                                });
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    const destination = activeFilters?.destination || '';
                                    const destinationLabel = getDestinationName(activeFilters?.destination) || '';

                                    const searchParams = new URLSearchParams({
                                        destination,
                                        destinationLabel,
                                    }).toString();

                                    history.push(`/agent/${agent.agentId}?${searchParams}`, {
                                        state: { bookedVisitCnt: agent.bookedVisitCnt },
                                    });
                                }
                            }}
                        >
                            {agent.firstName}
                            {' '}
                            {agent.lastName}
                            {' '}
                        </span>
                    </div>
                    <div className="text-blue-dark text-sm">{agent.agencyName}</div>
                    <div className="text-black text-sm">
                        {agent.city}
                        ,
                        {agent.state}
                    </div>
                    <div className="md:hidden text-xs text-blue-dark">
                        <BookedVisited bookedVisitCnt={agent.bookedVisitCnt} />
                    </div>
                </div>
            </div>

            <div className="md:p-2 px-4 w-full">
                <div className="flex flex-col h-full">
                    <div className="text-black text-xl font-semibold cursor-pointer hidden md:block">
                        <span
                            className="hover:underline"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                const destination = activeFilters?.destination || '';
                                const destinationLabel = getDestinationName(activeFilters?.destination) || '';

                                const searchParams = new URLSearchParams({
                                    destination,
                                    destinationLabel,
                                }).toString();

                                history.push(`/agent/${agent.agentId}?${searchParams}`, {
                                    state: { bookedVisitCnt: agent.bookedVisitCnt },
                                });
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    const destination = activeFilters?.destination || '';
                                    const destinationLabel = getDestinationName(activeFilters?.destination) || '';

                                    const searchParams = new URLSearchParams({
                                        destination,
                                        destinationLabel,
                                    }).toString();

                                    history.push(`/agent/${agent.agentId}?${searchParams}`, {
                                        state: { bookedVisitCnt: agent.bookedVisitCnt },
                                    });
                                }
                            }}
                        >
                            {`${agent.firstName} ${agent.lastName}`}
                        </span>
                        <span className="text-blue-dark text-xs">{`/ ${agent.agencyName} / `}</span>
                        <span className="text-black text-xs">{` ${agent.city}, ${agent.state}`}</span>
                    </div>
                    <div className="flex flex-col">
                        {agent.agentRating && agent.agentRating.length > 0 && (
                            <div className="cursor-pointer text-black text-xs flex flex-col gap-1" onClick={() => goToAgentBio(agent, 'review')}>
                                <div>
                                    <StarRatings
                                        rating={agent.agentRating[0].rating}
                                        starRatedColor="#33C590"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="14px"
                                        starSpacing="2px"
                                    />
                                </div>
                                <div>
                                    <span>
                                        {agent.agentRating[0].totalReviews}
                                        {agent.agentRating[0].totalReviews === 1 ? ' review' : ' reviews'}
                                    </span>
                                    <span> / </span>
                                    <span>
                                        {((agent.agentRating[0].recommended / agent.agentRating[0].totalReviews) * 100).toFixed(2)}
                                    </span>
                                    <span>% Recommended</span>

                                    {agent.yearsActive && (
                                        <>
                                            <span className="hidden md:inline"> / </span>
                                            <br className="md:hidden" />
                                            <span>{agent.yearsActive}</span>
                                            <span> Years of Experience</span>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    {agent.isOffline === '1' && (
                        <div className="flex justify-center items-center w-full my-2 bg-gray-light text-xs md:text-sm text-black rounded-lg font-bold p-1">
                            I am currently not accepting customers inquiries
                        </div>
                    )}
                    <div className="flex flex-col gap-1 text-xs mt-2 text-black">
                        {agent.destination && (
                            <div>
                                <span className="mr-1 inline-block font-semibold">Destination Specialties:</span>
                                {agent.destination.map((x, index) => (
                                    <span className="mr-1 inline-block" key={`${index}-dest`}>
                                        {x.locationType === 'Cit' ? x.localeName : (x.locationType === 'Reg' ? x.regionName : x.areaName)}
                                        {index + 1 < agent.destination.length ? ',' : ''}
                                    </span>
                                ))}
                            </div>
                        )}
                        {agent.interest && agent.interest.length > 0 && (
                            <div>
                                <span className="mr-1 inline-block font-semibold">Interest Specialties:</span>
                                <span>
                                    {agent.interest.map((x) => x.interestName).join(', ')}
                                </span>
                            </div>
                        )}
                        <div className="hidden md:block">
                            {BookedVisited({ ...agent })}
                        </div>
                        <div className="md:w-128">
                            {agent.topReview && (
                                <div className="flex flex-col gap-1 text-xs md:my-2 my-2 md:mx-1 mx-3 text-black bg-gray-light rounded-2xl p-6">
                                    <div className="font-medium">{`${agent.topReview[0].topReviewName} says`}</div>
                                    <div className="py-1 italic quote ">{parser(agent.topReview[0].topReview).length > 100 ? `${parser(agent.topReview[0].topReview).slice(0, 100)}...` : parser(agent.topReview[0].topReview)}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    {agent.websiteDisplayTitle && (
                        <div className="text-blue-500 text-base my-2 hidden">{agent.websiteDisplayTitle}</div>
                    )}
                </div>
                <div className="flex flex-col items-end pr-4 md:hidden">
                    {actionButtonsMob(agent)}
                </div>
            </div>

            <div className="hidden md:flex md:flex-col md:items-end md:pr-4">
                {actionButtonsDesk(agent)}
                {profileButton(agent)}
            </div>
        </div>
    );

    const BookedVisited = ({ bookedVisitCnt }) => {
        if (!activeFilters?.destination || !bookedVisitCnt?.length) {
            return null;
        }

        const { bookedCnt = 0, visitedCnt = 0 } = bookedVisitCnt[0] || {};
        if (bookedCnt <= 2 && visitedCnt <= 2) {
            return null;
        }

        return (
            <>
                {(bookedCnt > 25 || bookedCnt >= 1 || visitedCnt > 25 || visitedCnt >= 1) && (
                    <>
                        <div className="md:flex flex-row items-center gap-8">
                            {bookedCnt >= 1 && (
                                <span className="mr-8 pt-1 inline-flex items-center font-semibold text-blue-dark">
                                    <IoCheckmarkCircleSharp className="mr-1 text-green-500" />
                                    {' '}
                                    {bookedCnt > 25 ? 'Booked 25+' : bookedCnt >= 3 ? `Booked ${bookedCnt}` : 'Booked'}
                                    {' '}
                                    {getDestinationName(activeFilters?.destination)}
                                    {' '}
                                    trips
                                </span>
                            )}
                            {visitedCnt >= 1 && (
                                <span className="inline-flex items-center font-semibold text-blue-dark">
                                    <IoLocationSharp className="mr-1 text-green-500" />
                                    {' '}
                                    Traveled to
                                    {' '}
                                    {getDestinationName(activeFilters?.destination)}
                                    {' '}
                                    {visitedCnt > 25 ? '25+ times' : visitedCnt >= 3 ? `${visitedCnt} times` : ''}
                                </span>
                            )}
                        </div>

                        <div className="hidden md:hidden flex flex-col items-start gap-2">
                            {bookedCnt >= 1 && (
                                <span className="inline-flex items-center font-semibold text-blue-dark">
                                    <IoCheckmarkCircleSharp className="mr-1 text-green-500" />
                                    {' '}
                                    {bookedCnt > 25 ? 'Booked 25+' : bookedCnt >= 3 ? `Booked ${bookedCnt}` : 'Booked'}
                                    {' '}
                                    {getDestinationName(activeFilters?.destination)}
                                    {' '}
                                    trips
                                </span>
                            )}
                            {visitedCnt >= 1 && (
                                <span className="inline-flex items-center font-semibold text-blue-dark">
                                    <IoLocationSharp className="mr-1 text-green-500" />
                                    {' '}
                                    Traveled to
                                    {' '}
                                    {getDestinationName(activeFilters?.destination)}
                                    {' '}
                                    {visitedCnt > 25 ? '25+ times' : visitedCnt >= 3 ? `${visitedCnt} times` : ''}
                                </span>
                            )}
                        </div>
                    </>
                )}
            </>
        );
    };

    BookedVisited.propTypes = {
        bookedVisitCnt: PropTypes.arrayOf(
            PropTypes.shape({
                bookedCnt: PropTypes.number,
                visitedCnt: PropTypes.number,
            }),
        ),
    };

    BookedVisited.defaultProps = {
        bookedVisitCnt: [],
    };

    const hasActiveFilter = () => activeFilters.destination || activeFilters.interest
        || activeFilters.state || activeFilters.agentName || activeFilters.zipCode
        || activeFilters.metro || activeFilters.language || activeFilters.supplier;
    const groupedSpecialities = () => {
        const items = [];
        getSpecialities().map((x) => {
            if (items.some((y) => y.name === x.category)) {
                items.find((y) => y.name === x.category).items.push({
                    value: x.interest,
                    label: x.interest,
                });
            } else {
                items.push({
                    type: 'group',
                    name: x.category,
                    items: [{
                        value: x.interest,
                        label: x.interest,
                    }],
                });
            }

            return true;
        });

        return items;
    };

    const sideFilter = () => (
        <div className="flex flex-col px-6 bg-blue-dark h-full mt-3 md:mt-0 min-h-screen">
            <div className="md:hidden">{filterRender()}</div>
            <div className="md:flex flex-col">

                <div className="fixed md:top-0 w-full z-50 md:z-10  left-0 right-0 h-full text-white  md:relative md:flex flex-col bg-blue-dark">
                    <div className="overflow-y-auto  md:overflow-y-hidden h-full pb-20 px-4 md:px-0 md:pt-8">

                        <div className="md:hidden pt-4 md:pt-0 mx-auto">
                            <BreadCrumb links={links} inverted />
                            {dynamicTitle && (
                                <h1 className="text-xl md:text-4xl font-semibold text-white py-4 uppercase">
                                    {dynamicTitle}
                                </h1>
                            )}

                        </div>
                        <div className="sticky top-0 md:hidden bg-blue-dark border-b border-white py-2 -mx-4 px-4 -mt-4">
                            <div className="my-2 font-semibold text-sm uppercase text-white md:hidden" onClick={() => setFilter(!filter)}>Close Filter</div>
                            {activeFiltersRender()}
                        </div>
                        <div>
                            <State
                                callback={(type, value) => {
                                    setZipCode();
                                    setSelectedZipCodeTemp();

                                    if (type === 'state') {
                                        if (value === 0) {
                                            setSelectedState();
                                            setSelectedCity();
                                            setSelectedMetro();
                                            setActiveFilters(
                                                {
                                                    ...activeFilters, state: '', metro: '', city: '', zipCode: '',
                                                },
                                            );

                                            return;
                                        }
                                        setSelectedMetro();
                                        setSelectedCity();
                                        setSelectedState(value);
                                        setActiveFilters(
                                            {
                                                ...activeFilters, metro: '', city: '', zipCode: '',
                                            },
                                        );
                                    }

                                    if (type === 'metro') {
                                        if (value === 0) {
                                            setSelectedMetro();
                                            setSelectedCity();
                                            setActiveFilters(
                                                {
                                                    ...activeFilters, metro: '', city: '', zipCode: '',
                                                },
                                            );
                                            return;
                                        }
                                        setActiveFilters(
                                            {
                                                ...activeFilters, city: '', zipCode: '',
                                            },
                                        );
                                        setSelectedCity();
                                        setSelectedMetro(value);
                                    }

                                    if (type === 'city') {
                                        if (value === 0) {
                                            setSelectedCity();
                                            setActiveFilters(
                                                { ...activeFilters, city: '', zipCode: '' },
                                            );

                                            return;
                                        }
                                        setActiveFilters(
                                            { ...activeFilters, zipCode: '' },
                                        );
                                        setSelectedCity(value);
                                    }
                                }}
                                trigger={() => {
                                    if (activeFilters.state !== selectedState || activeFilters.metro !== selectedMetro || activeFilters.city !== selectedCity) { searchViaRoute(); }
                                }}
                                state={selectedState}
                                destination={selectedDestinationObject ? selectedDestinationObject?.label?.split('|')[0].trim() : ''}
                                interest={selectedInterest}
                                language={selectedLanguage}
                                supplier={selectedSupplier}
                                metroRegion={selectedMetro}
                                zip={zipCode}
                                city={selectedCity}
                            />
                        </div>

                        {/* <div className="text-center border-b border-blue-200 py-2">{label('Destinations')}</div> */}

                        <div>
                            <Destination
                                destination={selectedDestinationObject ? selectedDestinationObject?.label?.split('|')[0].trim() : ''}
                                callback={(value) => {
                                    if (value === 0) {
                                        setSelectedDestination();
                                        setSelectedDestinationObject(null);
                                        setSelectedDestinationObjectTemp(null);
                                        setActiveFilters(
                                            { ...activeFilters, destination: '' },
                                        );

                                        return;
                                    }
                                    setSelectedDestination(value);
                                    setSelectedDestinationObject({
                                        value: getDestinations
                                            .find((x) => x.destination === value)
                                            .destinationId,
                                        label:
                                            getDestinations
                                                .find((x) => x.destination === value)
                                                .destination,

                                    });
                                }}
                                trigger={() => {
                                    if (activeFilters.destination !== selectedDestination) { searchViaRoute(); }
                                }}
                                interest={selectedInterest}
                                language={selectedLanguage}
                                supplier={selectedSupplier}
                                state={selectedState}
                                metroRegion={selectedMetro}
                                zip={zipCode}
                                city={selectedCity}
                            />
                        </div>

                        <div>
                            <Interest
                                destination={selectedDestinationObject ? selectedDestinationObject?.label?.split('|')[0].trim() : ''}
                                callback={(value) => {
                                    setSelectedInterest(value);
                                }}
                                trigger={() => {
                                    if (activeFilters.interest !== selectedInterest) { searchViaRoute(); }
                                }}
                                interest={selectedInterest}
                                language={selectedLanguage}
                                supplier={selectedSupplier}
                                state={selectedState}
                                metroRegion={selectedMetro}
                                zip={zipCode}
                                city={selectedCity}
                            />
                        </div>

                        <div>
                            <Supplier
                                destination={selectedDestinationObject ? selectedDestinationObject?.label?.split('|')[0].trim() : ''}
                                callback={(value) => {
                                    setSelectedSupplier(value);
                                }}
                                trigger={() => {
                                    if (activeFilters.supplier !== selectedSupplier) { searchViaRoute(); }
                                }}
                                interest={selectedInterest}
                                language={selectedLanguage}
                                supplier={selectedSupplier}
                                state={selectedState}
                                metroRegion={selectedMetro}
                                zip={zipCode}
                                city={selectedCity}
                            />
                        </div>

                        <div>
                            <Language
                                destination={selectedDestinationObject ? selectedDestinationObject?.label?.split('|')[0].trim() : ''}
                                callback={(value) => {
                                    setSelectedLanguage(value);
                                }}
                                trigger={() => {
                                    if (activeFilters.language !== selectedLanguage) { searchViaRoute(); }
                                }}
                                interest={selectedInterest}
                                language={selectedLanguage}
                                supplier={selectedSupplier}
                                state={selectedState}
                                metroRegion={selectedMetro}
                                zip={zipCode}
                                city={selectedCity}
                            />
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );

    const filterRender = () => (
        <>
            {/* <div className={['my-2 font-semibold text-sm uppercase text-white md:hidden', activeFilters.destination || activeFilters.interest || activeFilters.state ? 'hidden' : '', filter ? 'hidden' : ''].join(' ')} onClick={() => setFilter(!filter)}>Filter Results</div> */}
            <div className={['flex flex-col py-1  text-sm bg-blue-dark sticky top-12 md:top-44 w-full', visiibleFilter ? 'md:pl-64 pl-3' : '', filter ? 'hidden' : ''].join(' ')} style={{ zIndex: 30 }}>
                <div className="my-2 font-semibold text-sm uppercase text-white md:hidden" onClick={() => setFilter(!filter)}>Filters results</div>
                {activeFiltersRender()}

            </div>

        </>
    );

    const activeFiltersRender = () => (
        <>
            {hasActiveFilter() && (
                <div className="flex flex-row gap-2  items-center text-white">
                    <div className="flex text-white items-center gap-2">
                        <div className="my-2 font-semibold text-sm uppercase text-white">ACTIVE FILTERS</div>
                        <div>
                            {' | '}
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-4">
                        {activeFilters.destination
                            && (
                                <div
                                    className="flex flex-row  gap-1 cursor-pointer items-center"
                                    onClick={() => {
                                        setSelectedDestination();

                                        setActiveFilters(
                                            { ...activeFilters, destination: '' },
                                        );
                                        setSelectedDestinationObject(null);
                                        setSelectedDestinationObjectTemp(null);
                                        setIsReady(true);
                                    }}
                                >
                                    <div>
                                        <IoClose className="text-red-500" />
                                    </div>
                                    <div className="font-bold">
                                        Destination:
                                    </div>
                                    <div>
                                        {getDestinationName()}
                                    </div>
                                </div>
                            )}
                        {(activeFilters.interest)
                            && (
                                <div
                                    className="flex flex-row items-center gap-1 cursor-pointer"
                                    onClick={() => {
                                        setSelectedInterest();
                                        setSelectedInterestTemp();
                                        setActiveFilters(
                                            { ...activeFilters, interest: '' },
                                        );
                                        setIsReady(true);
                                    }}
                                >
                                    <div>
                                        <IoClose className="text-red-500" />
                                    </div>
                                    <div className="font-bold">
                                        Interest:
                                    </div>
                                    <div>
                                        {activeFilters.interest}
                                    </div>
                                </div>
                            )}
                        {activeFilters.state
                            && (
                                <div
                                    className="flex flex-row items-center items-center gap-1 "
                                    onClick={() => {
                                        setSelectedState();
                                        setSelectedZipCodeTemp();
                                        setSelectedMetro();
                                        setActiveFilters(
                                            { ...activeFilters, metro: '', state: '' },
                                        );
                                        setIsReady(true);
                                    }}
                                >
                                    <div>
                                        <IoClose className="text-red-500" />
                                    </div>
                                    <div className="font-bold">
                                        Agent State:
                                    </div>
                                    <div>
                                        {getStateName(activeFilters.state)}
                                    </div>
                                </div>
                            )}

                        {activeFilters.agentName
                            && (
                                <div
                                    className="flex flex-row items-center gap-1"
                                    onClick={() => {
                                        setAgentName('');

                                        setActiveFilters(
                                            { ...activeFilters, agentName: '' },
                                        );
                                        setIsReady(true);
                                    }}
                                >
                                    <div>
                                        <IoClose className="text-red-500" />
                                    </div>
                                    <div className="font-bold">
                                        Name:
                                    </div>
                                    <div>
                                        {activeFilters.agentName}
                                    </div>
                                </div>
                            )}

                        {activeFilters.zipCode
                            && (
                                <div
                                    className="flex flex-row items-center gap-1"
                                    onClick={() => {
                                        setZipCode();
                                        setSelectedZipCodeTemp();

                                        setActiveFilters(
                                            { ...activeFilters, zipCode: '' },
                                        );
                                        setIsReady(true);
                                    }}
                                >
                                    <div>
                                        <IoClose className="text-red-500" />
                                    </div>
                                    <div className="font-bold">
                                        Location:
                                    </div>
                                    <div>

                                        {activeFilters.zipCode}
                                    </div>
                                </div>
                            )}

                        {activeFilters.metro && !activeFilters.city
                            && (
                                <div
                                    className="flex flex-row items-center gap-1"
                                    onClick={() => {
                                        setSelectedMetro();
                                        setSelectedCity();
                                        setActiveFilters(
                                            { ...activeFilters, metro: '', city: '' },
                                        );
                                        setIsReady(true);
                                    }}
                                >
                                    <div>
                                        <IoClose className="text-red-500" />
                                    </div>
                                    <div className="font-bold">
                                        Metro:
                                    </div>
                                    <div>

                                        {activeFilters.metro}
                                    </div>
                                </div>
                            )}

                        {activeFilters.city
                            && (
                                <div
                                    className="flex flex-row items-center gap-1"
                                    onClick={() => {
                                        setSelectedCity('');
                                        setActiveFilters(
                                            { ...activeFilters, city: '' },
                                        );
                                        setIsReady(true);
                                    }}
                                >
                                    <div>
                                        <IoClose className="text-red-500" />
                                    </div>
                                    <div className="font-bold">
                                        City:
                                    </div>
                                    <div>

                                        {activeFilters.city}
                                    </div>
                                </div>
                            )}

                        {activeFilters.language
                            && (
                                <div
                                    className="flex flex-row items-center gap-1"
                                    onClick={() => {
                                        setSelectedLanguage('');
                                        setActiveFilters(
                                            { ...activeFilters, language: '' },
                                        );
                                        setIsReady(true);
                                    }}
                                >
                                    <div>
                                        <IoClose className="text-red-500" />
                                    </div>
                                    <div className="font-bold">
                                        Language:
                                    </div>
                                    <div>

                                        {activeFilters.language}
                                    </div>
                                </div>
                            )}

                        {activeFilters.supplier
                            && (
                                <div
                                    className="flex flex-row items-center gap-1"
                                    onClick={() => {
                                        setSelectedSupplier('');
                                        setActiveFilters(
                                            { ...activeFilters, supplier: '' },
                                        );
                                        setIsReady(true);
                                    }}
                                >
                                    <div>
                                        <IoClose className="text-red-500" />
                                    </div>
                                    <div className="font-bold">
                                        Supplier:
                                    </div>
                                    <div>

                                        {activeFilters.supplier}
                                    </div>
                                </div>
                            )}

                    </div>
                </div>
            )}
        </>
    );

    const loader = () => (
        <div className=" max-w-screen-2x px-4 min-h-screen ">
            <div>
                <div className="py-4 w-1/6 inline-block align-top">
                    <Skeleton count={1} height={1200} />
                </div>
                <div className="w-5/6 inline-block pl-2">
                    <div className="py-4">
                        <Skeleton count={1} height={256} />
                    </div>
                    {Array.apply(null, Array(10)).map((r, i) => (
                        <div className=" mb-10" key={`skel${i}`}>
                            <Skeleton height={195} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    const ad = (index) => {
        if (agents?.agent.length < 3 && ads.length > 0) {
            return index === agents?.agent.length ? (
                <a href={ads[0].adURL + ads[0].adTracking} className="cursor-pointer mx-4">
                    <img className="mx-auto" src={`${AD_URL()}${detectMobile.isDesktop() ? ads[0].adFile : ads[0].adFileMobile}`} alt={ads[0].adName} />
                </a>
            ) : undefined;
        }

        const showAd = index % 3 === 0 || agents?.agent.length < 3;
        if (showAd) {
            const key = index < 3 ? 0 : (index / 3) - 1;
            return key < ads.length ? (
                <a href={ads[key].adURL + ads[key].adTracking} className="cursor-pointer mx-4">
                    <img className="mx-auto" src={`${AD_URL()}${detectMobile.isDesktop() ? ads[key].adFile : ads[key].adFileMobile}`} alt={ads[key].adName} />
                </a>
            ) : undefined;
        }
        return undefined;
    };

    return (
        <div role="main">
            <div className=" max-w-screen-2xl mx-auto">
                <Helmet>
                    <title>{dynamicTitle}</title>
                    <meta name="title" content={dynamicMetaTitle} />
                    <meta name="description" content={dynamicDescription} />
                </Helmet>
                <ToastContainer />
                <div className="flex flex-col md:flex-col py-10 justify-center text-xs pb-4  bg-white">
                    <div className="bg-gray-light p-4 sticky top-12  max-w-screen-2xl w-full z-50 hidden md:block">
                        <div className="text-center ">
                            {/* {label('REFINE SEARCH RESULTS')} */}

                            <div className="flex flex-col lg:flex-row text-center py-2 gap-4 text-sm md:items-end md:pr-10">
                                <div className="flex flex-col  w-full md:w-3/4">
                                    {label('Location')}
                                    <div>
                                        <Input
                                            placeholder="Enter Zip/Postal Code"
                                            value={selectedZipCodeTemp}
                                            onChange={(val) => setSelectedZipCodeTemp(val)}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center w-full md:w-3/4">
                                    <div>
                                        {label('Specialty')}
                                        <div>
                                            <SelectInput
                                                options={[{ label: 'Select a Travel Interest', value: '' }].concat(groupedSpecialities())}
                                                placeholder="Select a travel interest"
                                                onSelected={(val) => {
                                                    if (val === '') {
                                                        setSelectedInterestTemp('');
                                                    } else setSelectedInterestTemp(val);
                                                }}
                                                value={selectedInterestTemp}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center w-full md:w-3/4">
                                    <div>
                                        {label('Destination')}
                                        <div>
                                            <Select
                                                aria-label="Destination"
                                                styles={customSelectStyles}
                                                placeholder="Destination"
                                                className="w-full rounded-full text-xs"
                                                options={[{ label: 'Destination', value: '' }].concat(getDestinations.map((x) => ({
                                                    value: x.destinationId,
                                                    label: x.destination,
                                                })))}
                                                onChange={(val) => {
                                                    if (val.value === '') {
                                                        setSelectedDestinationObjectTemp(null);
                                                    } else { setSelectedDestinationObjectTemp(val); }
                                                }}
                                                onInputChange={(val) => {
                                                    if (val !== '') {
                                                        setIsMenuOpen(true);
                                                    } else setIsMenuOpen(false);
                                                }}
                                                value={selectedDestinationObjectTemp}
                                                menuIsOpen={isMenuOpen}
                                                filterOption={createFilter(filterConfig)}
                                                noOptionsMessage={({ inputValue }) => (!inputValue || 'Sorry, no matching results')}
                                                components={{ DropdownIndicator: () => null }}
                                            />

                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-2 w-full md:w-3/4 ">
                                    <Button title="SEARCH" onClick={() => onRefineSearch()} />

                                </div>

                            </div>

                        </div>
                    </div>
                    {visiibleFilter && <>{filterRender()}</>}
                    {agents ? (
                        <div className="flex flex-row   max-w-screen-2xl">
                            <div className="hidden md:flex h-full">
                                {sideFilter()}
                            </div>

                            {filter && (
                                <div className="flex md:hidden">
                                    {sideFilter()}
                                </div>
                            )}

                            <div className={[' text-4xl flex flex-col items-center md:items-start text-white  align-top  flex-1  min-h-screen mt-0 md:mt-0', filter ? ' hidden-tw md:flex' : ''].join(' ')}>
                                <div className="absolute right-0 z-20 top-28 md:top-56">
                                    <img
                                        alt="Decotarion vector 5"
                                        src="images/Vector 5.webp"
                                        className="md:w-full -mr-40 md:mr-0"
                                    />
                                </div>
                                <div className="relative lg:flex mx-auto  flex flex-col w-full   px-4 md:px-0 bg-blue-dark md:pl-0 py-10 z-10 ">
                                    <BreadCrumb links={links} inverted />
                                    {dynamicTitle && (
                                        <h1 className="text-xl md:text-4xl font-semibold text-white py-4 uppercase">
                                            {dynamicTitle}
                                        </h1>
                                    )}
                                    <div className="text-sm md:text-md font-semibold">
                                        There are
                                        {' '}
                                        {agents?.totalAgents}
                                        {' '}
                                        agents matching this search
                                    </div>
                                    <div className="text-xs md:text-sm flex mb-2 md:pr-20">
                                        These trusted agents are a part of Internova Travel Group, an International family of travel brands,
                                        and can provide you with personalized recommendations, detailed knowledge of destinations and exclusive deals & amenities for your next trip.
                                    </div>
                                    {/* <div className="my-2 font-semibold text-sm uppercase text-green-light md:hidden" onClick={() => setFilter(!filter)}>Filters results</div> */}
                                    {filterRender()}
                                </div>
                                <div className="p-4 pr-0 bg-transparent md:bg-gray-light rounded-tl-2xl -mt-3.5  z-20 w-full">
                                    <div className={[
                                        'flex flex-col gap-4  w-full'].join(' ')}
                                    >
                                        {agents?.agent.map((result, index) => (
                                            <div key={index}>
                                                {agentCard(result)}
                                                {ads.length > 0 && (
                                                    <>
                                                        {ad(index + 1)}
                                                    </>
                                                )}
                                            </div>
                                        ))}

                                    </div>
                                    {agents?.agent.length !== parseInt(agents?.totalAgents) && agents?.agent.length !== 0
                                        && agents?.agent.length >= 20 && (
                                            <div className="my-4 justify-center flex flex-col items-center gap-4 w-full md:w-1/3 mx-auto">
                                                {isFetchingAgentMore && <LoadingInline />}
                                                <Button
                                                    title="LOAD MORE AGENTS"
                                                    onClick={() => {
                                                        setCurrentPage(currentPage + 1);
                                                    }}
                                                />

                                            </div>

                                        )}
                                </div>

                            </div>
                        </div>
                    ) : loader()}
                    <div className="  z-20   hidden md:block -ml-12">
                        <img
                            alt="Decoration vector 9"
                            src="images/Vector 9.webp"
                            className=""
                        />
                    </div>
                </div>

                <Modal
                    isOpen={isEmailFormModalOpen || isPhoneFormModalOpen || isAppointmentFormModalOpen}
                    onRequestClose={() => closeModal()}
                    style={modalCustomStyles}
                    contentLabel="Example Modal"
                >
                    <div className="z-50">
                        <div className="flex justify-end cursor-pointer" onClick={() => closeModal()}>
                            <IoClose className="text-red-500 text-right" />
                        </div>
                        {isEmailFormModalOpen && <EmailForm agent={selectedAgent} />}
                        {isPhoneFormModalOpen && (
                            <PhoneForm
                                agent={selectedAgent}
                                phone={selectedAgent.connect.find((x) => x.contactMethod === 'by Phone')?.contactValue}
                                callBack={() => {
                                    setIsPhoneFormModalOpen(false);
                                    setIsEmailFormModalOpen(true);
                                }}
                            />
                        )}

                        {isAppointmentFormModalOpen && (
                            <AppointmentForm
                                agent={selectedAgent}
                            />
                        )}

                        {isEmergencyFromModalOpen && <EmergencyEmailForm agent={selectedAgent} />}

                    </div>
                </Modal>

                {isFetchingAgent() && <Loading />}
            </div>
        </div>
    );
}

export default Agents;
